import { TokenV3 } from '../../model'
import { getMarketDisplayName } from '../../utils'

export const feeTooltipText =
  'Final transaction fee paid to network validators for verifying your transaction.'

export const tipTooltipText =
  "Tips are optional but always appreciated! We don't charge you any additional fees but tips support the development of DexGuru!"

export const scanTooltipText =
  'Check your transaction on the block explorer to confirm the details yourself!'

export const scanErrorTooltipText =
  'Review your transaction on the block explorer in detail to diagnose the error further.'

export const linkTooltipText = 'Click to open your TX on the block explorer.'

export const txnErrorTooltip =
  'TX Error parsed from the block explorer. Please note, details may be limited due to the block explorer.'

export const buildTweetUrl = (message: string): string =>
  `https://twitter.com/intent/tweet?text=${encodeURIComponent(message)}`

export const buildTokenTweet = (token: TokenV3): string =>
  `Check out $${getMarketDisplayName(token)} ${window.location.origin}/token/${token.id}`
